<template>
  <coupon-loader v-if="isLoading">loading</coupon-loader>
  <v-form
    ref="form"
    v-model="isValid"
    lazy-validation
    v-else
  >
    <v-container fluid>
      <v-card class="px-6">
        <v-card-title
          primary-title
          class="pb-3"
        >
          <span class="text-h3">{{ action }}</span>
        </v-card-title>
        <v-card-text class="pa-0">
          <div class="d-flex flex-row flex-md-row gap-150">
            <v-flex xs6>
              <v-row class="mt-0">
                <v-col cols="4">
                  <v-text-field
                    outlined
                    clearable
                    dense
                    v-model="coupon.code"
                    :label="labels.code"
                    :rules="rules.code"
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    outlined
                    dense
                    :label="labels.type"
                    v-model="coupon.type"
                    :items="types"
                    :rules="rules.type"
                    @change="changeType"
                  />
                </v-col>
                <v-col cols="5">
                  <v-autocomplete
                    outlined
                    clearable
                    dense
                    v-model="coupon.hubs"
                    :items="hubs"
                    item-text="name"
                    item-value="id"
                    :label="$t('hubs')"
                    multiple
                    chips
                    small-chips
                    :loading="is_loading_hubs"
                    :search-input.sync="search_hub_text"
                    no-filter
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row
                class="mt-0"
                v-if="isFlatType"
              >
                <v-col class="d-flex gap-10">
                  <v-text-field
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    v-model="coupon.amount"
                    :label="labels.amount"
                    :rules="rules.amount"
                  />
                  <v-text-field
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    v-model="coupon.target_amount"
                    :label="labels.target_amount"
                    :rules="rules.target_amount"
                  />
                </v-col>
              </v-row>
              <v-row
                class="mt-0"
                v-if="isPercentageType"
              >
                <v-col class="d-flex gap-10">
                  <v-text-field
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    max="100"
                    v-model="coupon.percentage"
                    :label="labels.percentage"
                    :rules="rules.percentage"
                  />
                  <v-text-field
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    v-model="coupon.target_amount"
                    :label="labels.target_amount"
                    :rules="rules.target_amount"
                  />
                  <v-text-field
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    v-model="coupon.max_off"
                    :label="labels.max_off"
                    :rules="rules.max_off"
                  />
                </v-col>
              </v-row>

              <v-row class="mt-0">
                <v-col class="d-flex gap-10">
                  <v-text-field
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    v-model="coupon.max_usage"
                    :label="labels.max_usage"
                    :rules="rules.max_usage"
                  />
                  <v-text-field
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    v-model="coupon.max_usage_per_day"
                    :label="labels.max_usage_per_day"
                    :rules="rules.max_usage_per_day"
                  />
                  <v-text-field
                    outlined
                    clearable
                    dense
                    type="number"
                    min="0"
                    v-model="coupon.max_usage_per_user"
                    :label="labels.max_usage_per_user"
                    :rules="rules.max_usage_per_user"
                  />
                </v-col>
              </v-row>
            </v-flex>
            <v-flex xs6>
              <v-row class="mt-0">
                <v-col class="d-flex gap-10">
                  <date-time-picker-input
                    :label="this.$t('start_date')"
                    v-model="coupon.start_at"
                    :rules="[
                      (value) => {
                        return (
                          new Date(coupon?.start_at).getTime() <
                            new Date(coupon?.end_at).getTime() ||
                          'Start date should be inferior to end date'
                        );
                      },
                    ]"
                  ></date-time-picker-input>
                  <date-time-picker-input
                    :label="this.$t('end_date')"
                    v-model="coupon.end_at"
                    :rules="[
                      (value) => {
                        return (
                          new Date(coupon.end_at).getTime() >
                            new Date(coupon.start_at).getTime() ||
                          'End date should be superior to the start date'
                        );
                      },
                    ]"
                  ></date-time-picker-input> </v-col
              ></v-row>
              <div>
                <v-switch
                  v-model="coupon.status"
                  :label="$t('status')"
                >
                </v-switch>
              </div>
            </v-flex>
          </div>
        </v-card-text>
        <v-card-actions>
          <small>{{ labels.required_field }}</small>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="primary"
            class="me-2"
            @click="submit"
            :loading="isLoading"
            :disabled="isLoading"
          >
            {{ action }}
          </v-btn>
          <v-btn
            text
            @click="returnToList"
            >{{ labels.cancel }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  mixins: [validationRules],
  components: {
    DateTimePickerInput: () =>
      import("@/components/base/DateTimePickerInput.vue"),
    CouponLoader: () => import("./CouponLoader.vue"),
  },
  computed: {
    ...mapGetters({
      hubs: "hubs/listHubs",
      isLoading: "coupons/isLoading",
    }),
    coupon: {
      get() {
        return this.$store.state.coupons.coupon;
      },
      set(value) {
        this.$store.commit("coupons/ADD_COUPON", value);
      },
    },
    isFlatType() {
      return this.coupon.type === "flat";
    },
    isPercentageType() {
      return this.coupon.type === "percentage";
    },
    action() {
      return this.$t(this.coupon?.id ? "coupons.update" : "coupons.create");
    },
  },
  watch: {
    "coupon.end_at"() {
      if (this.$refs.form) {
        this.$refs.form.validate();
      }
    },
    search_hub_text: function (search) {
      this.search_hub(search);
    },
  },

  data() { 
    return {
      isValid: false,
      types: ["flat", "percentage"],
      is_loading_hubs: false,
      search_hub_text: "",

      labels: {
        code: this.$t("coupons.code"),
        type: this.$t("coupons.type"),
        amount: this.$t("coupons.amount"),
        target_amount: this.$t("coupons.target_amount"),
        required_field: this.$t("indicates_required_field"),
        cancel: this.$t("cancel"),
        percentage: this.$t("coupons.percentage"),
        max_off: this.$t("coupons.max_off"),
        max_usage: this.$t("coupons.max_usage"),
        max_usage_per_day: this.$t("coupons.max_usage_per_day"),
        max_usage_per_user: this.$t("coupons.max_usage_per_user"),
      },
      rules: {
        code: [(v) => !!v || this.$t("coupons.code_required")],
        type: [(v) => !!v || this.$t("coupons.type_required")],
        amount: [(v) => !!v || this.$t("coupons.amount_required")],
        target_amount: [
          (v) => !!v || this.$t("coupons.target_amount_required"),
        ],
        max_off: [(v) => !!v || this.$t("coupons.max_off_required")],
        max_usage: [(v) => !!v || this.$t("coupons.max_usage_required")],
        percentage: [(v) => !!v || this.$t("coupons.percentage_required")],
        max_usage_per_day: [
          (v) => !!v || this.$t("coupons.max_usage_per_day_required"),
        ],
        max_usage_per_user: [
          (v) => !!v || this.$t("coupons.max_usage_per_user_required"),
        ],
      },
    };
  },
  methods: {
    changeType() {
      if (this.coupon.type === "percentage") {
        delete this.coupon.amount;
      } else {
        delete this.coupon.max_off;
        delete this.coupon.percentage;
      }
    },
    async submit() {
      await this.$refs.form.validate();
      if (this.isValid) {
        try {
          if (this.coupon.id) {
            await this.$store.dispatch("coupons/update", this.coupon);
            await this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("coupons/add", this.coupon);
            await this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }

          this.$router.push("/coupons");
        } catch (error) {
          await this.$store.dispatch(
            "alerts/error",
            error.response?.data?.message
          );
        }
      }
    },
    returnToList() {
      this.$swal({
        icon: "question",
        text: this.$t("coupons.return"),
        showConfirmButton: false,
        showDenyButton: true,
        showCancelButton: true,
        denyButtonText: this.$t("coupons.dont_save"),
      }).then((result) => {
        if (result.isDenied) {
          this.$router.push("/coupons");
        }
      });
    },
    async loadCoupon(id) {
      try {
        await this.$store.dispatch("coupons/loadCoupon", id);
      } catch (error) {
        await this.$store.dispatch(
          "alerts/error",
          error.response?.data?.message
        );
      }
    },
    search_hub: debounce(function (search) {
      if (this.is_loading_hubs) return;
      this.is_loading_hubs = true;

      this.$store
        .dispatch("hubs/list", {
          itemsPerPage: 30,
          search,
        })
        .then(() => {
          this.is_loading_hubs = false;
        });
    }, 1000),
  },
  async mounted() {
    this.search_hub();
  },
  async created() {
    const id = this.$route.params.id;
    if (id) {
      this.loadCoupon(id);
    }
  },
};
</script>
<style scoped>
.gap-10 {
  gap: 10px;
}
.gap-150 {
  gap: 150px;
}
</style>